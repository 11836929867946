import React, { useState } from "react";
import { Link as GatsbyLink } from "gatsby";
import { Paragraph, TextContainer, Ul } from "@siteimprove/fancylib";
import {
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	Code,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { TableToolbar } from "../table-toolbar/table-toolbar";
import { useFilterGroup, useSingleFilter } from "./filters";

export const Meta: DocPageMeta = {
	category: "Tables and lists", // Which category does the component belong to?,
	title: "Filters", // Should the name in the styleguide menu be different from the component name?,
	notepad: undefined, //Go to https://hackmd.io/ , create an account, and create a new note. Provide the url to the note here.
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Filters"
			subTitle="Help users quickly and efficiently narrow down data sets within tables or lists."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={[useFilterGroup, useSingleFilter]} />
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					Filters help users quickly and efficiently narrow down data sets within tables or lists.
					They provide control and customization, allowing users to focus on the most relevant
					information.
				</Paragraph>
				<Header.H3>Basic usage with single filter</Header.H3>
				<Paragraph>
					Allows users to refine data based on one specific criterion. Ideal when there's a single
					primary way users categorize or sort information.
				</Paragraph>
				<Paragraph>
					<b>Use Cases</b>:
				</Paragraph>
				<Ul
					items={[
						<>Filtering a list of modules (e.g., "DCI”, “Accessibility“, “Quality Assurance“).</>,
						<>Filtering search results by content (e.g., "Title", "URL").</>,
					]}
				/>
				<Example fn={SingleFilterBasicUsage} />
				<Header.H3>Basic usage with filter group</Header.H3>
				<Paragraph>
					Combines multiple filters for applying several criteria simultaneously. Great for complex
					data sets with varying user needs. The criteria for filtering should be well-defined and
					relatively stable.
				</Paragraph>
				<Paragraph>
					<b>Use Cases</b>:
				</Paragraph>
				<Ul
					items={[
						<>Filtering an issue list by decisions, conformance, and difficulty</>,
						<>Filtering active policies by priority, category, module and access.</>,
					]}
				/>
				<Example fn={FilterGroupBasicUsage} />
				<Header.H3>Usage with default filter value</Header.H3>
				<Paragraph>
					Setting default filter values can streamline initial interactions and guide users toward
					common starting points.
				</Paragraph>
				<Paragraph>
					<b>Use Cases</b>:
				</Paragraph>
				<Ul
					items={[
						<>Setting the default data filter to "All categories" in Core Wins</>,
						<>Setting the default sort order to "Impact: High to low" in SEO {">"} Insight .</>,
					]}
				/>
				<Paragraph>
					When using <Code>useFilterGroup</Code> or <Code>useSingleFilter</Code>, you can pass a
					default value to the filter by using the <Code>defaultOption</Code> property in the
					filter's definition. This is useful when you want to set a default value for a filter, for
					example when the user has not interacted with the filter yet. Also, whenever the user
					clears the filter, the default value will be set again.
				</Paragraph>

				<Paragraph>
					<b>Best Practices</b>:
				</Paragraph>
				<Ul
					items={[
						<>Choose default values that are relevant and helpful to the majority of users.</>,
						<>
							Clearly indicate that a filter has a default value (e.g., using static{" "}
							<GatsbyLink to="/lab/components/Actions and controls/Pills">Pill</GatsbyLink>).
						</>,
						<>Allow users to easily change the default value.</>,
					]}
				/>

				<Header.H4>Single filter</Header.H4>
				<Example fn={SingleFilterWithDefaultValue} />
				<Header.H4>Filter group</Header.H4>
				<Example fn={FilterGroupWithDefaultValue} />
				<Header.H3>Usage with data-observe-keys</Header.H3>
				<Paragraph>
					The <Code>data-observe-key</Code> attribute allows you to track user interactions with the
					filters. This can be valuable for analytics and understanding user behavior.
				</Paragraph>
				<Paragraph>
					<b>Use Cases</b>:
				</Paragraph>
				<Ul
					items={[
						<>Tracking which filters are most frequently used.</>,
						<>Analyzing how users combine different filters.</>,
						<>Identifying potential usability issues with the filtering process.</>,
					]}
				/>
				<Paragraph>
					Use <Code>data-observe-key</Code> on the main component and/or individual filters to
					create identifiers, which are useful for tracking user interactivity, for example. Inner
					buttons and components, such as confirm, cancel, clear, pills, will be assigned with the
					same <Code>data-observe-key</Code> plus a discriminator. For instance, when using{" "}
					<Code>{'useFilterGroup(..., ..., ..., { "data-observe-key": "foo" })'}</Code>, the clear
					all button will be assigned with <Code>foo-ClearAll</Code>. See below a complete example
					and note this pattern in action.
				</Paragraph>
				<Paragraph>
					<b>Best Practices</b>:
				</Paragraph>
				<Ul
					items={[
						<>
							Assign unique <Code>data-observe-key</Code> values to each filter and its
							sub-components.
						</>,
						<>
							Use a consistent naming convention for <Code>data-observe-key</Code> values. Read more
							in{" "}
							<a href="https://siteimprove-wgs.atlassian.net/wiki/x/MQATbw">
								Process for Tagging Elements (Data-observe-keys)
							</a>
						</>,
						<>
							Ensure that the <Code>data-observe-key</Code> values are meaningful and easy to
							interpret.
						</>,
					]}
				/>
				<Example fn={UsageWithDataObserveKeys} />
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>useFilterGroup</Code> and <Code>useSingleFilter</Code> when
					</Paragraph>
					<Ul
						items={[
							<>
								<b>Efficient data exploration is critical</b>: Users need to quickly locate specific
								information within extensive datasets.
							</>,
							<>
								<b>Customization and personalization are valued</b>: Users have varying preferences
								for how they filter and refine data.
							</>,
							<>
								<b>Multiple filtering dimensions are available</b>: The data can be filtered across
								various attributes or categories.
							</>,
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						<Code>useFilterGroup</Code> and <Code>useSingleFilter</Code> are typically used in the
						following places:
					</Paragraph>
					<Ul
						items={[
							<>
								<GatsbyLink to="/lab/components/Tables and lists/Table toolbar">Toolbar</GatsbyLink>
								: The most common and visible location, typically above the table or list being
								filtered.
							</>,
							<>
								<GatsbyLink to="/lab/components/Overlay/Side panel">Side panel</GatsbyLink>:
								Adjacent to the content, particularly useful when screen space is limited.
							</>,
							<>
								<GatsbyLink to="/lab/components/Overlay/Modal">Modal</GatsbyLink>: For extensive
								filter options, modals provide a focused environment.
							</>,
						]}
					/>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								<b>Siteimprove Design System</b>: Adhere to Siteimprove's guidelines for color,
								typography, and spacing. If you are not using a component from Fancy, match the
								styling of your <Code>useFilterGroup</Code> and <Code>useSingleFilter</Code> to
								existing components for visual consistency.
							</>,
							<>
								<b>Clear filter option</b>: Always provide a way to reset all filter selections
								(e.g., "Clear" button, "x" icon).
							</>,
							<>
								<b>Limit options</b>: Avoid overwhelming users with too many filter options per
								group (aim for 5-7).
							</>,
							<>
								<b>Consistent placement</b>: Maintain consistent filter placement throughout your
								application for predictability.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							"Users can easily scan all items, filters are unnecessary.",
							<>
								One or two broad filters might be better integrated directly into the content
								presentation. Use <GatsbyLink to="/lib/components/Navigation/Tabs">Tabs</GatsbyLink>{" "}
								instead.
							</>,
							<>
								Criteria are constantly changing or interconnected, consider a search
								<GatsbyLink to="/lab/components/Forms and input/Input Field">
									Input Field
								</GatsbyLink>{" "}
								with{" "}
								<GatsbyLink to="/lab/components/Forms and input/Auto Complete">
									Autocomplete
								</GatsbyLink>{" "}
								instead.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Header.H4>For designers</Header.H4>
					<Ul items={["Ensure sufficient color contrast, clear labels."]} />
					<Header.H4>For developers</Header.H4>
					<Paragraph>
						This component comes with built-in accessibility, no extra work required.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={["Keep filter labels concise and clear.", "Avoid jargon and technical terms."]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const SingleFilterBasicUsage = () => {
	type Country = { id: string; name: string };
	const countries: Country[] = [
		{ id: "DK", name: "Denmark" },
		{ id: "SE", name: "Sweden" },
		{ id: "NO", name: "Norway" },
		{ id: "FI", name: "Finland" },
	];
	const [country, setCountry] = useState<Country | undefined>(countries[0]);

	const onChange = (newValue: Country | undefined) => {
		console.log("Filter changed, calling API with new country", newValue);
		setCountry(newValue);
	};

	const [filterButton, activeFilters] = useSingleFilter(country, onChange, {
		label: "Country",
		stringify: (country) => country?.name,
		items: countries.map((country) => ({ title: country.name, value: country })),
		compareFn: (a, b) => a.id === b.id,
	});

	return <TableToolbar filter={filterButton} activeFilters={activeFilters} />;
};

const FilterGroupBasicUsage = () => {
	// these types look ambiguous, but they are just examples
	// of how to use filters with objects, but you can use any
	// type you want, including primitives
	type BaseFilter = { id: string; name: string };
	type Country = BaseFilter;
	type Device = BaseFilter;
	type Browser = BaseFilter;

	type Filters = {
		country: Country;
		device?: Device;
		browsers?: Browser[];
	};

	const countries: Country[] = [
		{ id: "DK", name: "Denmark" },
		{ id: "SE", name: "Sweden" },
		{ id: "NO", name: "Norway" },
		{ id: "FI", name: "Finland" },
	];

	const devices: Device[] = [
		{ id: "desktop", name: "Desktop" },
		{ id: "mobile", name: "Mobile" },
		{ id: "tablet", name: "Tablet" },
		{ id: "laptop", name: "Laptop" },
	];

	const browsers: Browser[] = [
		{ id: "chrome", name: "Chrome" },
		{ id: "edge", name: "Edge" },
		{ id: "firefox", name: "Firefox" },
		{ id: "safari", name: "Safari" },
	];

	const [filters, setFilters] = useState<Filters>({
		country: countries[0],
		device: devices[1],
		browsers: [browsers[0], browsers[1]],
	});

	const onChange = (newFilters: Filters) => {
		console.log("Filters changed, calling API with new filters", newFilters);
		setFilters(newFilters);
	};

	const [filterGroup, activeFilters] = useFilterGroup<Filters>(filters, onChange, [
		{
			label: "Country",
			property: "country",
			items: countries.map((country) => ({ title: country.name, value: country })),
			compareFn: (a, b) => a?.id === b?.id,
			stringify: ({ country }) => country?.name,
		},
		{
			label: "Device",
			property: "device",
			items: devices.map((device) => ({ title: device.name, value: device })),
			compareFn: (a, b) => a?.id === b?.id,
			stringify: ({ device }) => device?.name,
		},
		{
			label: "Browsers",
			property: "browsers",
			items: browsers.map((browser) => ({ title: browser.name, value: browser })),
			compareFn: (a, b) => a?.id === b?.id,
			searchable: "always",
			stringify: ({ browsers }) =>
				(browsers || []).length > 0 ? browsers?.map((b) => b.name).join(", ") : undefined,
		},
	]);

	return <TableToolbar filter={filterGroup} activeFilters={activeFilters} />;
};

const SingleFilterWithDefaultValue = () => {
	type Country = { id: string; name: string };
	const countries: Country[] = [
		{ id: "DK", name: "Denmark" },
		{ id: "SE", name: "Sweden" },
		{ id: "NO", name: "Norway" },
		{ id: "FI", name: "Finland" },
	];
	const [country, setCountry] = useState<Country | undefined>(countries[0]);

	const onChange = (newValue: Country | undefined) => {
		console.log("Filter changed, calling API with new country", newValue);
		setCountry(newValue);
	};

	const [filterButton, activeFilters] = useSingleFilter(country, onChange, {
		label: "Country",
		name: "country",
		defaultOption: countries[0],
		stringify: (country) => country?.name,
		items: countries.map((country) => ({ title: country.name, value: country })),
		compareFn: (a, b) => a.id === b.id,
	});

	return <TableToolbar filter={filterButton} activeFilters={activeFilters} />;
};

const FilterGroupWithDefaultValue = () => {
	const countries = [
		{ id: "DK", name: "Denmark" },
		{ id: "SE", name: "Sweden" },
		{ id: "NO", name: "Norway" },
		{ id: "FI", name: "Finland" },
	];
	const devices = [
		{ id: "desktop", name: "Desktop" },
		{ id: "mobile", name: "Mobile" },
		{ id: "tablet", name: "Tablet" },
		{ id: "laptop", name: "Laptop" },
	];
	const [filters, setFilters] = useState({
		country: countries[0],
		devices: [devices[0], devices[1]],
	});

	const [filterGroup, activeFilters] = useFilterGroup(filters, setFilters, [
		{
			label: "Country",
			name: "country",
			property: "country",
			defaultOption: countries[0],
			stringify: ({ country }) => country?.name,
			items: countries.map((country) => ({ title: country.name, value: country })),
			compareFn: (a, b) => a.id === b.id,
		},
		{
			label: "Devices",
			name: "devices",
			property: "devices",
			stringify: ({ devices }) =>
				(devices || []).length > 0 ? devices?.map((b) => b.name).join(", ") : undefined,
			items: devices.map((device) => ({ title: device.name, value: device })),
			compareFn: (a, b) => a.id === b.id,
		},
	]);

	return <TableToolbar filter={filterGroup} activeFilters={activeFilters} />;
};

const UsageWithDataObserveKeys = () => {
	const countries = [
		{ id: "DK", name: "Denmark", "data-observe-key": "CountryFilter-DK" },
		{ id: "SE", name: "Sweden", "data-observe-key": "CountryFilter-SE" },
		{ id: "NO", name: "Norway", "data-observe-key": "CountryFilter-NO" },
		{ id: "FI", name: "Finland", "data-observe-key": "CountryFilter-FI" },
	];
	const devices = [
		{ id: "desktop", name: "Desktop", "data-observe-key": "DeviceFilter-desktop" },
		{ id: "mobile", name: "Mobile", "data-observe-key": "DeviceFilter-mobile" },
		{ id: "tablet", name: "Tablet", "data-observe-key": "DeviceFilter-tablet" },
		{ id: "laptop", name: "Laptop", "data-observe-key": "DeviceFilter-laptop" },
	];
	const [filters, setFilters] = useState({
		country: countries[0],
		devices: [devices[0], devices[1]],
	});

	const [filterGroup, activeFilters] = useFilterGroup(
		filters,
		setFilters,
		[
			{
				label: "Country",
				name: "country",
				property: "country",
				stringify: ({ country }) => country?.name,
				items: countries.map((country) => ({
					title: country.name,
					value: country,
					"data-observe-key": country["data-observe-key"],
				})),
				compareFn: (a, b) => a.id === b.id,
				"data-observe-key": "CountryFilter",
			},
			{
				label: "Devices",
				name: "devices",
				property: "devices",
				stringify: ({ devices }) =>
					(devices || []).length > 0 ? devices?.map((b) => b.name).join(", ") : undefined,
				items: devices.map((device) => ({
					title: device.name,
					value: device,
					"data-observe-key": device["data-observe-key"],
				})),
				compareFn: (a, b) => a.id === b.id,
				"data-observe-key": "DeviceFilter",
			},
		],
		{
			"data-observe-key": "FilterGroup-Example",
		}
	);

	return <TableToolbar filter={filterGroup} activeFilters={activeFilters} />;
};
